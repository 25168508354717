*,
*::before,
*::after {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

html {
    scroll-behavior: smooth;
    font-size: 62.5%; // 1rem -> 10px
}

body {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

header {
    flex: 1;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-direction: column;
    padding: 5rem;
}

header h1 {
    font-size: 16rem;
    color: #43AB8E;
}

header .subtitle {
    color: rgb(146, 146, 146);
    margin-top: -1.9rem;
    font-size: 3.5rem;
}

footer {
    height: 12rem;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2rem;
    background-color: #43AB8E;
    color: white;
    width: 100vw;

    p {
        font-size: 2.6rem;
        text-align: center;
    }
}

.App {
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.work-progress {
    height: 40vmin;
    pointer-events: none;
}

.work-progress-container {
    font-size: 3.6rem;
    font-weight: bold;
    display: flex;
    justify-content: center;
}

.dots-container {
    width: 3rem;
}

.dots {
    white-space: nowrap;
    width: fit-content;
    overflow: hidden;
    text-align: start;
}

@media (prefers-reduced-motion: no-preference) {

    .dots {
        animation: work-in-progress infinite 2s linear;
    }
}

@keyframes work-in-progress {
    0% {
        width: 0rem;
    }

    25% {
        width: 1rem;
    }

    50% {
        width: 3rem;
    }

    75% {
        width: 1rem;
    }

    100% {
        width: 0rem;
    }
}


@media only screen and (max-width: 800px) {

    html {
        font-size: 50%;
    }
}


@media only screen and (max-width: 650px) {

    html {
        font-size: 40%;
    }

}

@media only screen and (max-width: 500px) {

    header {

        h1 {
            font-size: 10rem;
        }

        .subtitle {
            margin-top: -1rem;
            font-size: 2rem;
        }
    }

    .work-progress-container {
        font-size: 3rem;
    }
}